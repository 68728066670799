<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent  scrollable   max-width="600px">
                <v-card width="600px;">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                        <v-form id="form_update_occupancy" ref="form" v-model="valid" lazy-validation>

                        <v-text-field
                            v-model="number_of_parking_spaces"
                            label="Vseh parkirnih mest"
                            required
                            clearable
                            type="number"
                            append-icon="mdi-pencil"
                            :rules="ruleNumberOfParkingSpaces"                  
                        ></v-text-field>

                        <v-text-field
                            v-model="occupied_parking_spaces"
                            label="Zasedena mesta"
                            required
                            clearable
                            type="number"
                            append-icon="mdi-pencil"
                            :rules="[ruleMaxAllowedOccupiedParkingSpaces]"                  
                        ></v-text-field>

                        <v-text-field
                            v-model="free_parking_spaces"
                            label="Prosta mesta"
                            required
                            clearable
                            type="number"
                            append-icon="mdi-pencil"
                            :rules="[ruleMaxAllowedFreeParkingSpaces]"                  
                        ></v-text-field>

                        <v-checkbox class="ti" v-model="parking_restriction" :label="`Upoštevaj omejitev parkiranja: ${checkboxParkingRestrictionLabel}`"></v-checkbox>

                        <v-text-field
                            v-model="occupancy"
                            label="Zasedenost (%)"
                            required
                            append-icon="mdi-pencil"
                            readonly
                            filled             
                        ></v-text-field>

                        <v-text-field
                            v-model="updated_at_formatted"
                            label="Čas osvežitve"
                            required
                            append-icon="mdi-calendar-clock"
                            readonly
                            filled
                            :rules="[]"                  
                        ></v-text-field>

                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'edit-parking-occupancy-dialog',
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        id: null,
        uuid: null,
        name: null,
        updated_at: null,
        updated_at_formatted: null,
        number_of_parking_spaces: null,
        free_parking_spaces: null,
        occupied_parking_spaces: null,
        occupancy: 0,
        parking_restriction: null,


        valid: true,
        formTitle: "",
        dialog: false,
        item: null,
        loading: false,
        disableSubmit: false,
        cancelText: "Prekliči",

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        ruleNumberOfParkingSpaces: [],

        rules: {
            
            number_of_parking_spaces: value => !!value || 'Polje za vnos parkirnih mest je obvezno',
            free_parking_spaces: value => !!value ||'Polje za vnos prostih parkirnih mest je obvezno',
            occupied_parking_spaces: value => !!value || 'Polje za vnost zasedenih parkirnih mest je obvezno'
        }


    }),

    watch: {
        number_of_parking_spaces(value) {
            this.occupancyCalculation()
        },

        free_parking_spaces(value) {
            window.console.log(value)
            this.occupancyCalculation()
        },

        occupied_parking_spaces(value) {
            this.occupancyCalculation()
        },

        occupancy(value) {
            //window.console.log("Occupancy: " + value)
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        checkboxParkingRestrictionLabel() {
            if(this.parking_restriction) {
                return 'DA'
            }

            return 'NE'
        }
    },

    methods: {
        submitForm() {
            var vm = this
            
            if(this.$refs.form.validate()) {
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                /*vm.backendMessages.push('Podatki o zasedenosti za podjetje ' + vm.item.name + ' so bili uspešno posodobljeni.');
                vm.disableSubmit = true
                vm.cancelText = "Zaključi"

                setTimeout(() =>{
                    vm.loading = false
                },600)

                return;*/

                let data = {
                    id: vm.item.id,
                    uuid: vm.item.uuid,
                    number_of_parking_spaces: vm.number_of_parking_spaces,
                    occupied_parking_spaces: vm.occupied_parking_spaces,
                    free_parking_spaces: vm.free_parking_spaces,
                    parking_restriction: (vm.parking_restriction == 1 || vm.parking_restriction == true || vm.parking_restriction == "1") ? 1 : 0
                }

                // window.console.log("Sending data...")
                // window.console.log(data)

                vm.$store.dispatch('UPDATE_UNIT_PARKING_OCCUPANCY', data)
                .then(response => {
                    window.console.log(response)
                    vm.backendMessages.push('Podjetje / oseba ' + vm.item.name + ' je bilo uspešno posodobljena.');
                    vm.disableSubmit = true
                    vm.cancelText = "Zaključi"

                })
                .catch(error => {
                    console.log('UPDATE_UNIT_PARKING_OCCUPANCY error')
                    console.log(error)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodabljanju zasedenosti je prišlo do napake. Poskusite ponovno.')
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false
                    },600)
                })
            }
        },

        cancel() {
            this.clearForm()
            this.item = null
            this.uuid = null
            EventBus.$emit('refresh_dt_occupancy', null)
            this.loading = false
            this.disableSubmit = false
            this.dialog = false
            this.cancelText = "Prekliči"
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
        },

        setDefaultFormValues() {
            
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        setFormTitle() {
            if(this.item != null && this.item != undefined) {
                this.formTitle = "Podjetje - oseba: " +  this.item.name
                return
            }
        },

        ruleMaxAllowedOccupiedParkingSpaces() {
            var numberOfParkingSpaces = parseInt(this.number_of_parking_spaces);
            var freeParkingSpaces = parseInt(this.free_parking_spaces);
            var occupiedParkingSpaces = parseInt(this.occupied_parking_spaces);

            let sum = freeParkingSpaces + occupiedParkingSpaces;

            if( (occupiedParkingSpaces > numberOfParkingSpaces || (freeParkingSpaces + occupiedParkingSpaces) > numberOfParkingSpaces) && this.parking_restriction == 1 ) {
                return 'Vneseno število presega število vseh parkirnih mest'
            }

            return true
        },

        ruleMaxAllowedFreeParkingSpaces() {

            var numberOfParkingSpaces = parseInt(this.number_of_parking_spaces);
            var freeParkingSpaces = parseInt(this.free_parking_spaces);
            var occupiedParkingSpaces = parseInt(this.occupied_parking_spaces);

            let sum = freeParkingSpaces + occupiedParkingSpaces;

            /*window.console.log("SUM: " + sum)
            window.console.log("Number of parking spaces: " +numberOfParkingSpaces)
            window.console.log("Free parking spaces: " + freeParkingSpaces)
            window.console.log("Occupied parking spaces: " + occupiedParkingSpaces)*/

            if( (freeParkingSpaces > numberOfParkingSpaces || (freeParkingSpaces + occupiedParkingSpaces) > numberOfParkingSpaces ) && (this.parking_restriction == 1) ) {
                return 'Vneseno število presega število vseh parkirnih mest'
            }


            return true
        },

        occupancyCalculation() {
            let occupancyNumberCalculcation = (this.number_of_parking_spaces > 0 &&  (this.occupied_parking_spaces > 0 )) ? Math.floor( (parseInt(this.occupied_parking_spaces) / parseInt(this.number_of_parking_spaces)) * 100 ) : 0
            
            //window.console.log("Zasedenost: " + occupancyNumberCalculcation)
            this.occupancy = occupancyNumberCalculcation

            // window.console.log("Occupancy calculation: " + occupancyCalculcation)
        }
    },

    created() {
        var vm = this
        EventBus.$on('EDIT_PARKING_OCCUPANCY', function(data) {
            //window.console.log(data)
            vm.item = data
            vm.id = data.id
            vm.uuid = data.uuid
            vm.number_of_parking_spaces = data.number_of_parking_spaces
            vm.free_parking_spaces = data.free_parking_spaces
            vm.occupied_parking_spaces = data.occupied_parking_spaces
            vm.parking_restriction = (data.parking_restriction == 1 || data.parking_restriction == true) ? true : false
            vm.occupancy = data.occupancy
            vm.updated_at = data.updated_at
            vm.updated_at_formatted = data.updated_at_formatted

            vm.setFormTitle();
            vm.dialog = true;

            //window.console.log("Item:")
            //window.console.log(vm.item)
        });
    },

    mounted() {

    },

    beforeDestroy() {
        this.loading = false
        this.disableSubmit = false
        this.item = null
    }
}

</script>

<style scoped>
    .v-card {
        width:400px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield !important;
    }
</style>